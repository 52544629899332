import React from "react"
import update from "immutability-helper"
import * as CONST from "../constants"
import * as BS from "react-bootstrap"

const Drawer = props => {
  const [opened, setOpened] = React.useState(false)

  return (
    <>
      <nav className="nav nav-tabs" role="tablist">
        <a
          href="#tab-menu"
          role="tab"
          data-toggle="tab"
          aria-controls="tab-menu"
          aria-selected="true"
          className="nav-item nav-link active"
        >
          <i className="fas fa-bars" />
        </a>
        <a
          href="#tab-tree"
          role="tab"
          data-toggle="tab"
          aria-controls="tab-tree"
          aria-selected="false"
          className="nav-item nav-link"
        >
          <i className="far fa-user" />
        </a>
      </nav>
      <div className="tab-content">
        <div
          id="tab-menu"
          className="tab-pane tab-menu fade show active"
          role="tabpanel"
          aria-labelledby="tab-menu"
        >
          <ul>
            <li>
              <a href={`${props.base}`} className="nav-link">
                <i className="fas fa-home" />HOME
              </a>
            </li>
            <li>
              <a href={`${props.base}records/view`} className="nav-link">
                <i className="far fa-clock" />勤怠状況
              </a>
            </li>
            <li>
              <a href={`${props.base}transports`} className="nav-link">
                <i className="fas fa-subway" />営業交通費
              </a>
            </li>
            <li>
              <a href={`${props.base}requests`} className="nav-link">
                <i className="fas fa-inbox" />ワークフロー
              </a>
            </li>
            <li>
              {props.auth.employ_id===3 ? (
                <span className="nav-link disabled">
                  <i className="fas fa-video" />WEB会議室
                </span>
              ) : (
                <a href={`${props.base}meets`} className="nav-link">
                  <i className="fas fa-video" />WEB会議室
                </a>
              )}
            </li>
            <li>
              {props.auth.employ_id===3 ? (
                <span className="nav-link disabled">
                  <i className="far fa-calendar-alt" />スケジュール
                </span>
              ) : (
                <a href={`${props.base}schedules`} className="nav-link">
                  <i className="far fa-calendar-alt" />スケジュール
                </a>
              )}
            </li>
            <li>
              {props.auth.employ_id===3 ? (
                <span className="nav-link disabled">
                  <i className="fas fa-book" />設備予約
                </span>
              ) : (
                <a href={`${props.base}books`} className="nav-link">
                  <i className="fas fa-book" />設備予約
                </a>
              )}
            </li>
            <li>
              {props.auth.employ_id===3 ? (
                <span className="nav-link disabled">
                  <i className="fas fa-tasks" />業務管理
                </span>
              ) : (
                <a href={`${props.base}duties`} className="nav-link">
                  <i className="fas fa-tasks" />業務管理
                </a>
              )}
            </li>
            <li>
              {props.auth.employ_id===3 ? (
                <span className="nav-link disabled">
                  <i className="fas fa-chart-area"></i>成績グラフ
                </span>
              ) : (
                <a href={`${props.base}grades`} className="nav-link">
                  <i className="fas fa-chart-area"></i>成績グラフ
                </a>
              )}
            </li>
            {props.auth.role!=='member' && (
              <li>
                <a
                  href="#nav-setting"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="nav-setting"
                  className="nav-link"
                >
                  <i className="fas fa-ellipsis-h" />各種設定
                </a>
                <div className="collapse" id="nav-setting">
                  <ul>
                    <li>
                      <a href="/employs" className="nav-link">雇用区分管理</a>
                    </li>
                    <li>
                      <a href="/allowances" className="nav-link">特別手当管理</a>
                    </li>
                    <li>
                      <a href="/times" className="nav-link">勤怠状況管理</a>
                    </li>
                    <li>
                      <a href="/users" className="nav-link">ユーザー管理</a>
                    </li>
                    <li>
                      <a href="/groups" className="nav-link">グループ管理</a>
                    </li>
                    <li>
                      <a href="/rules" className="nav-link">勤務シフト管理</a>
                    </li>
                    <li>
                      <a href="/holidays" className="nav-link">休日管理</a>
                    </li>
                  </ul>
                </div>
              </li>
            )}
          </ul>
        </div>
        <div
          id="tab-tree"
          className="tab-pane tab-tree fade"
          role="tabpanel"
          aria-labelledby="tab-tree"
        >
          <DrawerTree
            list={props.tree}
            rootId={0}
            root={props.base}
            url={props.chat_url}
          />
        </div>
      </div>
    </>
  )
}

const DrawerTree = ({list, rootId, root, url}) => {
  const items =  list.filter(item => item.parent_id === rootId)
  const [opened, setOpened] = React.useState([])

  const onLoad = React.useCallback(() => {
    const state = {}
    items.map(item => {
      state[item.id] = false
    })
    setOpened(state)
  }, [])

  React.useEffect(() => {
    window.addEventListener('load', onLoad)
  }, [onLoad])

  const onClick = event => {
    event.preventDefault()
    const target = event.target.getAttribute("href")
    const id = target.replace(/^#tree/, '')

    if (typeof opened[id] !== "undefined") {
      const state = {}
      state[id] = !opened[id]
      setOpened(update(opened, {$merge: state}))
    }
  }

  return (
    <>
      {items.length>0 ? (
        <ul className="tree">
          {items.map(item => (
            <li key={item.id}>
              <a
                href={`#tree${item.id}`}
                className="nav-link collapse-toggle"
                aria-controls={`tree${item.id}`}
                aria-expanded={opened[item.id]}
                onClick={onClick}
              >
                {item.name}
              </a>
              <BS.Collapse in={opened[item.id]}>
                <div id={`tree${item.id}`} className="tree-child">
                  {item.user && item.user.map(user => (
                    <a key={user.id} href={`${url}${user.username}`} target="_blank">
                      <div
                        className="avatar"
                        style={{backgroundImage: `url(${root}${user.avatar})`}}
                      />
                      {user.disp_name ? user.disp_name : user.name}
                      {user.phone && <span className="phone">({user.phone})</span>}
                    </a>
                  ))}
                  {list.find(l => l.parent_id === item.id) &&
                    <DrawerTree
                      list={list}
                      rootId={item.id}
                      root={root}
                      url={url}
                    />
                  }
                </div>
              </BS.Collapse>
            </li>
          ))}
        </ul>
      ) : (
        <p class="text-center">ユーザーの一覧を表示できません</p>
      )}
    </>
  )
}

Drawer.defaultProps = {
  ...window[CONST.APP_NAME]
}

export default Drawer