import React from "react"
import { useMedia } from "react-use"
import { useThrottle } from "use-throttle"

const DrawerToggle = props => {
  const wide = useMedia(`(min-width: ${props.maxWidth}px)`)
  const backdrop = React.useRef(null)
  const [opened, setOpened] = React.useState(true)

  const open = () => {
    if (!backdrop.current) {
      const element = document.createElement('div')
      element.setAttribute('class', props.className.BACKDROP)
      element.setAttribute('data-dismiss', 'drawer')
      document.body.appendChild(element)
      backdrop.current = element
    }
    if (!wide) {
      backdrop.current.classList.add(props.className.SHOW)
    }
    document.body.classList.add(props.className.OPEN)
    setOpened(true)
  }

  const close = () => {
    if (backdrop.current) {
      backdrop.current.classList.remove(props.className.SHOW)
    }
    document.body.classList.remove(props.className.OPEN)
    setOpened(false)
  }

  const resize = React.useCallback(() => {
    wide ? open() : close()
  }, [wide])

  React.useEffect(() => {
    window.addEventListener('load', resize)
  }, [resize])

  const onResize = useThrottle(resize, 500)

  React.useEffect(() => {
    window.addEventListener('resize', onResize)
  }, [onResize])

  const onClick = event => {
    opened ? close() : open()
  }

  React.useEffect(() => {
    if (!backdrop.current) {
      return
    }
    backdrop.current.addEventListener('click', close)
  }, [close])

  return (
    <button
      onClick={onClick}
      aria-controls="drawer"
      aria-expanded="#drawer"
      className="drawer-toggle"
    >
      <span className="drawer-toggle-icon" />
    </button>
  )
}

DrawerToggle.defaultProps = {
  maxWidth: 768,
  className: {
    OPEN: "drawer-open",
    BACKDROP: "drawer-backdrop",
    SHOW: "show"
  }
}

export default DrawerToggle