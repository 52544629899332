import React from "react"
import * as CONST from "../constants"
import * as Util from "../util"
import * as BS from "react-bootstrap"

const Auth = props => {
  const name = props.auth.disp_name ? props.auth.disp_name : props.auth.name

  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <BS.Dropdown alignRight>
          <BS.Dropdown.Toggle variant="transparent">
            <i className="far fa-bell" />
            <span className="notify" />
          </BS.Dropdown.Toggle>
          <BS.Dropdown.Menu>
            <BS.Dropdown.Item eventKey="1">通知はありません</BS.Dropdown.Item>
          </BS.Dropdown.Menu>
        </BS.Dropdown>
      </li>
      <li className="nav-item">
        <BS.Dropdown alignRight>
          <BS.Dropdown.Toggle variant="transparent">
            <div className="avatar" />
          </BS.Dropdown.Toggle>
          <BS.Dropdown.Menu>
            <BS.Dropdown.Header>{name}</BS.Dropdown.Header>
            <BS.Dropdown.Item href={`${props.base}setting`}>
              <i className="fas fa-cog" />
              ユーザー設定
            </BS.Dropdown.Item>
            <BS.Dropdown.Divider />
            <BS.Dropdown.Item href={`${props.base}users/logout`}>
              <i className="fas fa-sign-out-alt" />
              ログアウト
            </BS.Dropdown.Item>
          </BS.Dropdown.Menu>
        </BS.Dropdown>
      </li>
    </ul>
  )
}

Auth.defaultProps = {
  ...window[CONST.APP_NAME]
}

export default Auth
