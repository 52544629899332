import React from 'react';
import ReactDOM from 'react-dom';

// Components
import Auth from "./component/auth"
import Drawer from "./component/drawer"
import DrawerToggle from "./component/drawer-toggle"

if (document.getElementById('auth')) {
  ReactDOM.render(<Auth />, document.getElementById('auth'))
}

if (document.getElementById('navigation')) {
  ReactDOM.render(<Drawer />, document.getElementById('navigation'))
}

if (document.getElementById('drawer-toggle')) {
  ReactDOM.render(<DrawerToggle />, document.getElementById('drawer-toggle'))
}
